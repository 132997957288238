import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useAsync } from 'react-async';
import { Link, useParams } from 'react-router-dom';
import { getAgenziaSuggestion, getAreas } from '../../utils/data';
import { usePageInfo } from '../../utils/use-page-info';
import { createArray, urlFriendlyName } from '../../utils/utils';
import ContentWrapper from '../layout/ContentWrapper';
import './style.scss';


const IndexProvince = () => {
    // TODO: not sure this dynamic loading get indexed properly by search engines
    const {data} = useAsync({
        promiseFn: useCallback(() => getAreas(), [])
    });

    useEffect(()=>{
        document.title = `Recensioni Agenzie Immobiliari per Provincia | GiustAgenzia.it`;
    })

    return <ContentWrapper className="main vertical-fill"><h1> Recensioni Agenzie Immobiliari per Provincia</h1>
            <div style={{columnWidth: "20em"}}>{
                data?.filter((x) => x.is_provincia)
                     .sort((a, b) => a.label.localeCompare(b.label))
                     .map((x) => <p key={x.id}><Link to={`/agenzie/${x.id}`}>{x.label}</Link></p>)
            }</div></ContentWrapper>
}

const IndexProvincia = (props: {provincia: string, pagina: number}) => {

    // TODO: use some central cache
    // TODO: not sure this dynamic loading get indexed properly by search engines
    const {data: dataAree} = useAsync({
        promiseFn: useCallback(() => getAreas(), [])
    });

    const {data} = useAsync({
        promiseFn: useCallback(() => getAgenziaSuggestion({is_provincia: true,provincia: props.provincia.toUpperCase(), id: props.provincia, label: ''}), [props.provincia])
    });
    const n = 50
    
    useEffect(()=>{
        if (dataAree !== undefined){
            document.title = `Recensioni Agenzie Immobiliari ${dataAree.find((x)=> x.id === props.provincia)?.label} | GiustAgenzia.it`;
        }
    }, [dataAree, props.provincia])

    const placeholder = createArray(n).map((x, i) => 
        <p key={i}>&nbsp;</p>
    )

    return <ContentWrapper className="main vertical-fill"><h1> Recensioni Agenzie Immobiliari {dataAree?.find((x)=> x.id === props.provincia)?.label}</h1>
    <div style={{columnWidth: "35em"}}>{
        data?.filter((x) => x.label !== undefined)
            .sort((a, b) => a.label.localeCompare(b.label)).slice(props.pagina * n, (props.pagina+1) * n )
                .map((x) => <p key={x.id}><Link to={`/recensioni-agenzia-immobiliare/${props.provincia}/${x.id}/${urlFriendlyName(x.label)}`}>{x.label}</Link></p>)
            ?? placeholder
    }</div>
    <hr></hr>
    <div style={{textAlign: "center"}}>{createArray(Math.ceil((data?.length ?? 0) / n)).map((_, i) => <Link style={{marginRight: "1em", fontWeight: i === props.pagina  ? 'bold' : 'normal'}} to={`/agenzie/${props.provincia}/${i}`}>{i+1} </Link> )}</div>
    </ContentWrapper>
}

const Index = () => {
    let {provincia, pagina} = useParams<{provincia?: string, pagina?: string}>();

    const { setPageInfo } = usePageInfo();
    useEffect(() => {
        setPageInfo({
            idArea: provincia?.toLowerCase(),
        });
    }, [provincia]);
    

    if (provincia === undefined){
        return <IndexProvince/>
    }
    else {
        return <IndexProvincia provincia={provincia} pagina={parseInt(pagina ?? '0')}/>
    }
}

export default Index