import './utils/firebase-init';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import TopBar from './components/blocks/TopBar';
import reportWebVitals from './reportWebVitals';
import SearchBand from './components/blocks/SearchBand';
import Footer from './components/blocks/Footer';
import Main from './components/blocks/Main';
import {
  BrowserRouter as Router,
  Switch, Route
} from "react-router-dom";
import Reviews from './components/blocks/Reviews';
import NewReview from './components/blocks/NewReview';
import { StylesProvider } from '@material-ui/styles';
import { ProvideAuth } from './utils/auth/use-auth';
import PrivateRoute from './utils/auth/PrivateRoute';
import Login from './components/blocks/Login';
import Register from './components/blocks/Register';
import OnBoarding from './components/blocks/Onboarding';
import { ProvideUser } from './utils/use-user';

import { SegmentAnalytics } from '@segment/analytics.js-core';
import LoginEmail from './components/blocks/LoginEmail';
import * as Sentry from '@sentry/react';
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import Index from './components/blocks/Index';
import { RELEASE } from './VERSION';
import TitleBand from './components/blocks/TitleBand';
import SelectForNewReview from './components/blocks/SelectForNewReview';
import LoginMagic from './components/blocks/LoginMagic';
import EmailUnsubscribeLanding from './components/blocks/EmailUnsubscribeLanding';
import { ProvidePageInfo } from './utils/use-page-info';
import NewAg from './components/blocks/NewAg';

declare global {
  interface Window {
    analytics?: SegmentAnalytics.AnalyticsJS & {load: (key: string) => void};
  }
}

if (process.env.NODE_ENV !== "production"){
  window.analytics = undefined;
  
} else {
  window.analytics?.load("xKXO4bh2CwLaKsq2fjsp73Bt7E2CYCBM");
  window.analytics?.page();

  Sentry.init({
    dsn: "https://9c798305c0db4580b0331df5af8a3f82@o815331.ingest.sentry.io/5807016",
    release: RELEASE,
    integrations: [new CaptureConsoleIntegration(
     {
       levels: ['error'],
     }
   )],
    });
}



ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={"Qualcosa è andato storto"} showDialog dialogOptions={{
      lang: 'it',
      title: "Sembra che qualcosa sia andato storto..",
      subtitle: "Il nostro team è stato allertato.",
      subtitle2: "Se puoi aiutarci, descrivici cos'è successo",
      labelSubmit: "Invia",
    }}>
      <StylesProvider injectFirst>
      <ProvideAuth><ProvideUser><ProvidePageInfo>
        <Router>
            <TopBar />
            <Switch>
              <Route path="/nuova-recensione">
                <TitleBand title="Nuova Recensione"/>
              </Route>
              <Route path="/aggiungi-agenzia">
                <TitleBand title="Nuova Agenzia Immobiliare"/>
              </Route>
              <Route >
                <SearchBand />
              </Route>
            </Switch>

            <Switch>
              {/* // TODO redirect if no more needed */}
              <Route exact path="/login">
                <Login/>
              </Route>
              
              <Route path="/login/email">
                <LoginEmail/>
              </Route>

              {/* This should be last. otherwise will match every login/something */}
              <Route exact path="/login/:tk">
                <LoginMagic />
              </Route>
              

              {/* // TODO redirect if no more needed */}
              <Route path="/register">
                <Register/>
              </Route>

              {/* // TODO redirect if no more needed */}
              <PrivateRoute unverifiedUsers path="/onboarding">
                <OnBoarding />
              </PrivateRoute>

              <Route path="/recensioni-agenzia-immobiliare/:provincia/:id/:nome?">
                <Reviews />
              </Route>
              
              <PrivateRoute path="/nuova-recensione/:provincia/:id">
                <NewReview />
              </PrivateRoute>

              <Route exact path="/nuova-recensione">
                <SelectForNewReview/>
              </Route>

              <PrivateRoute path="/aggiungi-agenzia">
                <NewAg/>
              </PrivateRoute>
              
              <Route path="/agenzie/:provincia?/:pagina?">
                <Index />
              </Route>

              <PrivateRoute exact path="/unsubscribe/followup-email">
                  <EmailUnsubscribeLanding/>
              </PrivateRoute>

              <Route path="/">
                <Main />
              </Route>
            </Switch>

            <Footer />
        </Router>
        </ProvidePageInfo></ProvideUser></ProvideAuth>
      </StylesProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined);
